import React from 'react'
import { useIntl } from 'gatsby-plugin-react-intl'
import StyledLi from './styles'
import classNames from 'classnames'

interface IProps {
  label: string
  icon?: string
  isSelected: boolean
  onClick: () => void
}

export const Option: React.FC<IProps> = ({ label, icon, isSelected, onClick }) => {
  const { formatMessage } = useIntl()

  return (
    <StyledLi className={ classNames({ selected: isSelected }) } onClick={ onClick }>
      {
        icon
          ?  <img className="flag" src={ `/images/flags/${ icon.toLowerCase() }.svg` } alt={ icon } />
          : null
      }
      <span className="label">{ formatMessage({ id: label.toLocaleLowerCase() }) }</span>
      {
        isSelected
          ? <img src={ `/images/ic-check.svg` } width={ 24 } height={ 24 } />
          : null
      }
    </StyledLi>
  )
}
