import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { Pill } from '../Pill'
import StyledSection from './styles'

interface IProps {
  initials: string[]
  initialSelected?: string
  attributes: string[]
  attributesSelected: string[]
  onInitialClick?: (initial: string) => void
  onAttributeClick?: (attribute: string) => void
}

export const Filters: React.FC<IProps> = ({
  initials, initialSelected, attributes, attributesSelected, onInitialClick, onAttributeClick
}) => {
  const sliderRef = useRef<HTMLDivElement>(null)
  const [areAttributesVisible, setAreAttributesVisible] = useState(false)

  const attributesItems = attributes.map(att => (
    <Pill
      key={ `attribute-${ att }` }
      label={ att }
      onClick={ attribute => handleAttributeClick(attribute) }
      isSelected={ attributesSelected.includes(att) }
    />
  ))

  const handleInitialClick = (initial: string) => {
    if (onInitialClick && typeof onInitialClick === 'function') onInitialClick(initial)
  }

  const handleAttributeClick = (attribute: string) => {
    if (onAttributeClick && typeof onAttributeClick === 'function') onAttributeClick(attribute)
  }

  const handleScrollToRight = () => {
    if (sliderRef && sliderRef.current) {
      sliderRef.current.scrollBy({ left: 66, behavior: 'smooth' })
    }
  }

  const handleScrollToLeft = () => {
    if (sliderRef && sliderRef.current) {
      sliderRef.current.scrollBy({ left: -66, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    if (initials && !initialSelected && onInitialClick && typeof onInitialClick === 'function') {
      onInitialClick(initials[0])
    }
  }, [initials.length])

  return (
    <StyledSection>
      <header>
        <section className="initials">
          <div className="prev" onClick={ handleScrollToLeft }>
            <img src={ `/images/ic-chevron-left.svg` } alt="Search" width={24} height={24} />
          </div>
          <div ref={ sliderRef ?? undefined } className="initials__container">
            {
              initials.map(initial => (
                <div
                  key={ initial }
                  className={ classNames('initial', { active: initial === initialSelected }) }
                  onClick={ () => handleInitialClick(initial) }
                >
                  <span>{ initial }</span>
                </div>
              ))
            }
          </div>
          <div className="next" onClick={ handleScrollToRight }>
            <img src={ `/images/ic-chevron-right.svg` } alt="Search" width={24} height={24} />
          </div>
        </section>
        
      </header>
      <section className={ classNames('attributes__container', { visible: areAttributesVisible }) }>
        { attributesItems }
      </section>
    </StyledSection>
  )
}
