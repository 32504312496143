import React, { ChangeEvent, useRef, useState } from 'react'
import { IOption, IStore } from '../../../models'
import { Select } from '../../shared'
import { Dropdown } from './Dropdown'
import StyledForm from './styles'

interface IProps {
  items: IStore[]
  countries: IOption[]
  selectedCountry?: IOption
  onCountryChange: (country: IOption) => void
}

export const StoreSearch: React.FC<IProps> = ({ items, countries, selectedCountry, onCountryChange }) => {
  const formRef = useRef<HTMLFormElement>(null)
  const [query, setQuery ] = useState('')

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.currentTarget.value)
  }

  const handleFormReset = () => {
    setQuery('')
  }

  return (
    <StyledForm ref={ formRef || null } onReset={ handleFormReset }>
      <header className="title">
        <h1>Finden Sie Ihre Gothaer Versicherung vor Ort</h1>
      </header>
      <section className="filter">
        <div className="countries" style={{display:  'none' }}>
          <Select
            value={ selectedCountry }
            options={ countries }
            labelProp="label"
            onChange={ onCountryChange }
            form={ formRef.current ?? undefined }
          />
        </div>
        <div className="input">
          <img src={ `/images/ic-search.svg` } alt="Search" width={24} height={24} />
          <input type="text" onChange={ handleQueryChange } value={ query } />
          <button type="reset">
            <img src={ `/images/ic-clear.svg` } alt="Search" width={24} height={24} />
          </button>
          <Dropdown
            query={ query }
            options={
              items.map(item => ({
                id: item.id.toString(),
                label: item.locationName,
                address: item.cleanedurlparts.adressLines,
                city: item.cleanedurlparts.locality,
                region: item.cleanedurlparts.region
              }))
            }
            isHidden={ !query }
          />
        </div>
      </section>
    </StyledForm>
  )
}
