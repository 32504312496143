import styled from 'styled-components'

export default styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 60px;

  > .title {
    h2 {
      color: #383A4D;
      font-size: 26px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 36px;
      margin: 0 0 10px;
    }
  }

  .tabs__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    overflow: hidden;

    .tabs {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      border-bottom: 1px solid #E3E4F3;

      .tab {
        box-sizing: border-box;
        padding: 16px 20px;
        color: #383A4D;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 24px;
        border-bottom: 4px solid transparent;

        &.active {
          border-bottom: 4px solid ${ ({ theme }) => theme.colors.main };
        }

        &:not(.active) {
          cursor: pointer;
        }

        .count {
          color: #A3A7C0;
          font-weight: normal;
        }
      }
    }

    .content {
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 4px;
      margin-top: 20px;

      @media screen and (max-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media screen and (max-width: 500px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
`
