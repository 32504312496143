import React, { RefObject } from 'react'
import StyledSection from './styles'

interface IProps {
  isHidden: boolean
}

export const Dropdown: React.FC<IProps> = ({ children, isHidden }) => {
  if (isHidden) return null

  return <StyledSection>{ children }</StyledSection>
}
