import { PageProps } from 'gatsby'
import React from 'react'
import { useIntl } from 'gatsby-plugin-react-intl'
import { Layout } from '../containers'
import { IBreadcrumbItem, IPageContext, IRegionPageContent, ISourceStore, IStore } from '../models'
import { Map, StoreCard, TextBlock } from '../components'
import { mapSourceStoreToStoreObject } from '../helpers'
import { CardsSection } from './styles'

const Region = (props: PageProps) => {
  const { formatMessage } = useIntl()
  const { pageContent } = props.pageContext as IPageContext<IRegionPageContent>
  const { region, stores, countryCode } = pageContent
  const regionStores: IStore[] = stores.map((s: ISourceStore) => mapSourceStoreToStoreObject(s))

  const breadCrumbItems: IBreadcrumbItem[] = [
    { label: formatMessage({ id: 'home' }), link: '/' },
    region
  ]

  const cards = regionStores.map(store => (
    <StoreCard key={ store.locationName } store={ store } />
  ))

  return (
    <Layout
      key={ `region-${ region.label }` }
      countryFilter={ countryCode }
      breadCrumbItems={ breadCrumbItems }
    >
      <section className="map">
        <Map stores={ regionStores } />
      </section>
      {/* <TextBlock
        title="Section title"
        // eslint-disable-next-line max-len
        text="Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi."
      /> */}
      <CardsSection>
        <h2>{ formatMessage({ id: 'ourShopsIn' }) } { region.label }</h2>
        <section className="cards">
          { cards }
        </section>
      </CardsSection>
    </Layout>
  )
}

export default Region
