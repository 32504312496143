import classNames from 'classnames'
import React, { useState } from 'react'
import {
  containCityStoreWithAttributes,
  containRegionStoreWithAttributes,
  getCities,
  getCitiesInitials,
  getCityRegion,
  getCityStoresCount,
  getCleanedUrl,
  getExistingStoresAttributes,
  getRegions,
  getRegionsInitials,
  getRegionStoresCount
} from '../../../helpers'
import { IStore } from '../../../models'
import { Filters } from './Filters'
import { Location } from './Location'
import StyledSection from './styles'

interface IProps {
  stores: IStore[]
}

export const StoreFilters: React.FC<IProps> = ({ stores }) => {
  const [isRegionTabVisible, setIsRegionTabVisible] = useState(false)
  const [initialSelected, setInitialSelected] = useState('')
  const [attributesSelected, setAttributesSelected] = useState<string[]>([])
  const availableRegions = getRegions(stores)
  const availableCities = getCities(stores)

  const locationsItems = () => {
    let locations: string[] = []
    if (isRegionTabVisible) {
      if (initialSelected && !attributesSelected.length) {
        locations = availableRegions
          .filter(region => initialSelected ? region.startsWith(initialSelected) : true)
      } else if (!initialSelected && attributesSelected.length) {
        locations = availableRegions
          .filter(region => containRegionStoreWithAttributes(stores, region, attributesSelected))
      } else if (initialSelected && attributesSelected.length) {
        locations = availableRegions
          .filter(region => initialSelected ? region.startsWith(initialSelected) : true)
          .filter(region => containRegionStoreWithAttributes(stores, region, attributesSelected))
      } else {
        locations = availableRegions
      }
    } else {
      if (initialSelected && !attributesSelected.length) {
        locations = availableCities
          .filter(city => city.startsWith(initialSelected))
      } else if (!initialSelected && attributesSelected.length) {
        locations = availableCities
          .filter(city => containCityStoreWithAttributes(stores, city, attributesSelected))
      } else if (initialSelected && attributesSelected.length) {
        locations = availableCities
          .filter(city => city.startsWith(initialSelected))
          .filter(city => containCityStoreWithAttributes(stores, city, attributesSelected))
      } else {
        locations = availableCities
      }
    }

    return locations.map(location => (
      <Location
        key={ location }
        label={ location }
        storesCount={ isRegionTabVisible
          ? getRegionStoresCount(stores, location)
          : getCityStoresCount(stores, location)
        }
        cleanedUrl={ getCleanedUrl(stores, location, isRegionTabVisible) }
        region={ getCityRegion(stores, location) }
        isRegion={ isRegionTabVisible }
      />
    ))
  }

  const handleCityTabClick = () => {
    if (isRegionTabVisible) {
      setIsRegionTabVisible(false)
      setInitialSelected('')
    }
  }

  const handleRegionTabClick = () => {
    if (!isRegionTabVisible) {
      setIsRegionTabVisible(true)
      setInitialSelected('')
    }
  }

  const handleSelectInitial = (initial: string) => {
    if (initial === initialSelected) setInitialSelected('')
    else setInitialSelected(initial)
  }

  const handleSelectAttribute = (attribute: string) => {
    const currentAttributes = attributesSelected.find(att => att === attribute)
      ? attributesSelected.filter(att => att !== attribute)
      : [...attributesSelected, attribute]
    setAttributesSelected(currentAttributes)
  }

  return (
    <StyledSection >
      <header className="title">
        <h2>Unsere Gothaer Standorte</h2>
      </header>
      <article className="tabs__container">
        <section className="tabs">
          <div
            className={ classNames('tab', { active: !isRegionTabVisible }) }
            onClick={ handleCityTabClick }
          >
            Städte <span className="count">({ availableCities.length })</span>
          </div>
          <div
            className={ classNames('tab', { active: isRegionTabVisible }) }
            onClick={ handleRegionTabClick }
          >
            Bundesländer <span className="count">({ availableRegions.length })</span>
          </div>
        </section>
        <Filters
          initials={ isRegionTabVisible ? getRegionsInitials(stores) : getCitiesInitials(stores) }
          initialSelected={ initialSelected }
          attributes={ getExistingStoresAttributes(stores) }
          attributesSelected={ attributesSelected }
          onInitialClick={ handleSelectInitial }
          onAttributeClick={ handleSelectAttribute }
        />
        <section className="content">
          { locationsItems() }
        </section>
      </article>
    </StyledSection>
  )
}
