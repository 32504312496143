import classNames from 'classnames'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import StyledSection from './styles'

interface IProps {
  images: string[]
}

export const ImageSlider: React.FC<IProps> = ({ images }) => {
  const sliderRef = useRef<HTMLDivElement>(null)
  const [index, setIndex] = useState(0);
  const [width, setWidth] = useState(0);
  const [xPosition, setXPosition] = useState(0);

  const imageItems = images.map((image, i) => (
    <div
      key={ `img-slide${ i }-${ image } ` }
      className={`image slide slide${ i }`}
      style={{ backgroundImage: `url(${ image })` }}
    />
  ))

  const dots = images.map((img, i) => (
    <span
      key={`dot-${ i }-${ img }`}
      className={ classNames('dot', { active: index === i }) }
      onClick={ () => handleDotClick(i) }
    />
  ))

  const handleClickPrev = () => {
    if (index === 0) return;
    setIndex(index - 1);
    setXPosition(xPosition + width);
  }

  const handleClickNext = () => {
    if (index === images.length - 1) {
      setIndex(0);
      setXPosition(0);
    } else {
      setIndex(index + 1);
      setXPosition(xPosition - width);
    }
  }

  const handleDotClick = (dotIndex: number) => {
    if (index === dotIndex) return
    if (dotIndex > index) {
      const indexDiff = dotIndex - index
      setIndex(dotIndex);
      setXPosition((xPosition * indexDiff) - width);
    } else {
      const indexDiff = index - dotIndex
      setIndex(dotIndex);
      setXPosition((xPosition * indexDiff) + width);
    }
  }

  useEffect(() => {
    let handleAutoplay: any
    if (images && images.length > 1) {
      handleAutoplay = setInterval(handleClickNext, 5000);
    }
    return () => {
      if (images && images.length > 1) clearInterval(handleAutoplay)
    }
  }, [handleClickNext]);

  useEffect(() => {
    if (sliderRef.current) {
      const width = sliderRef.current.clientWidth;
      setWidth(width);
    }
  }, [setWidth]);

  if (!images || !images.length) return null
  return (
    <StyledSection xPosition={xPosition}>
      <div className="image__container" ref={ sliderRef }>
        { imageItems }
      </div>
      {
        images && images.length > 1
          ? (
            <>
              <div className="prev" onClick={ handleClickPrev }>
                <img src={ `/images/ic-chevron-left-white.svg` } alt="Left" width={24} height={24} />
              </div>
              <div className="next" onClick={ handleClickNext }>
                <img src={ `/images/ic-chevron-right-white.svg` } alt="Right" width={24} height={24} />
              </div>
              <div className="dots">
                { dots }
              </div>
            </>
          ) : null
      }
    </StyledSection>
  )
}