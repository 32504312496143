import styled from 'styled-components'

export default styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 60px;
  overflow: hidden;

  .prev,
  .next {
    position: absolute;
    padding: 0 6px;
    cursor: pointer;
    z-index: 1000;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 30px;
  }

  .prev {
    left: 0;
  }
  
  .next {
    right: 0;
  }

  .dots {
    width: 200px;
    position: absolute;
    margin: auto;
    bottom: 17px;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .dot {
      width: 6px;
      height: 6px;
      margin: 3px;
      background-color: white;
      opacity: 0.5;
      transition: ease all 0.1s;
      border-radius: 3px;
      cursor: pointer;

      &.active {
        opacity: 1;
      }
    }
  }

  .image__container {
    position: relative;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;

    .image {
      flex: 1 0 100%;
      border-radius: 10px;
      height: 360px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      transition: transform 0.6s ease-in-out;
      transform: ${ (props: { xPosition: number }) => `translateX(${ props.xPosition }px)` };
    }
  }
`
