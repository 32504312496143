import { ISpecialDate } from '../models'

export const weekDays = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
]

export const openCloseSameDay = (
  startDay: string, startMonth: string, startYear: string, endDay: string, endMonth: string, endYear: string
): boolean => {
  return startDay === endDay && startMonth === endMonth && startYear === endYear
}

export const getNearDateIndex = (specialDates: ISpecialDate[]): number => {
  const todayTime = new Date().getTime()
  const specialDatesTimesDiff = specialDates.map(date => {
    const { year, month, day } = date.startDate
    const dateTime = new Date(year, month - 1, day).getTime()
    return todayTime - dateTime
  })
  return specialDatesTimesDiff.indexOf(Math.min(...specialDatesTimesDiff))
}