import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  width: calc(100% / 3 - 15px);
  border-radius: 10px;
  background-color: #FFFFFF;
  box-shadow: 0 4px 20px 0 rgba(0,0,0,0.1);
  padding: 20px;
  box-sizing: border-box;
  margin-right: 20px;
  margin-bottom: 20px;

  &:nth-child(3n) {
    margin-right: 0;
  }

  > header {
    .cover {
      height: 246px;
      width: 100%;
      border-radius: 10px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    /* img {
      width: 100%;
      height: auto;
      border-radius: 10px;
    } */

    h3 {
      color: #383A4D;
      font-size: 22px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 30px;
      margin: 20px 0 12px;
    }
  }

  .address {
    color: #383A4D;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    margin: 12px 0;
  }

  .contact {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: 28px;

    h4 {
      color: #383A4D;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 24px;
      margin: 0 0 12px;
    }

    p {
      margin: 0 0 12px;
    }

    .phone {
      color: ${ ({ theme }) => theme.colors.main };
      font-weight: normal;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 22px;

      a {
        color: ${ ({ theme }) => theme.colors.main };
      }
    }
  }

  footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: auto;

    a {
      width: 100%;
      border-radius: 22px;
      background-color: ${ ({ theme }) => theme.colors.main };
      padding: 10px 0;
      text-transform: uppercase;
      text-align: center;
      text-decoration: none;
      color: #FFFFFF;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 22px;
      transition: all ease 0.1s;

      &:active {
        background-color: ${ ({ theme }) => theme.colors.dark };
      }

      &:hover {
        background-color: ${ ({ theme }) => theme.colors.dark };
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: calc(100% / 2 - 15px);
    margin-right: 20px;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 500px) {
    width: 100%;
    margin-right: 0;
  }
`