import styled from 'styled-components'

export default styled.section`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 54px;
  right: 0;
  overflow-Y: scroll;
  overflow-x: hidden;
  box-sizing: border-box;
  max-height: 220px;
  min-width: 240px;
  border: 1px solid #EFEFEF;
  border-radius: 10px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 20px 0 rgba(0,0,0,0.2);
  z-index: 4000;
`
