import styled from 'styled-components'

export default styled.div`
  position: relative;
  margin-bottom: 20px;

  .current-state {
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    color: #FF7373;

    &.open {
      .arrow {
        transform: rotate(180deg);
      }
    }

    &.store-open {
      color: #66CD56;
    }

    img:first-of-type {
      margin-right: 8px;
      width: 24px;
      height: auto;
    }

    img:last-of-type {
      margin-left: 6px;
      width: 16px;
      height: auto;
    }
  }
  
  > ul {
    padding: 20px;
    box-sizing: border-box;
    list-style: none;
    width: 261px;
    box-sizing: border-box;
    border: 1px solid #EFEFEF;
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 20px 0 rgba(0,0,0,0.2);
    z-index: 4000;
    position: absolute;
    top: 34px;
    left: 0;
    margin: 0;

    li {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 10px 0;

      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
      
      span {
        color: #383A4D;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;
      }
    }
  }
`
