import { DefaultTheme } from 'styled-components'
import brandConfig from '../brand.json'

const theme: DefaultTheme = {
  colors: {
    main: brandConfig.mainColor,
    light: brandConfig.mainLight,
    dark: brandConfig.mainDark
  },
};

export { theme }
