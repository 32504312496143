import styled from 'styled-components'

export default styled.section`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 54px;
  right: 0;
  left: 0;
  border: 1px solid #EFEFEF;
  border-radius: 10px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 20px 0 rgba(0,0,0,0.2);
  overflow: hidden;
  z-index: 5000;

  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 380px;
    overflow-x: hidden;
    overflow-y: auto;
  }
`