import styled from 'styled-components'

export default styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 60px;

  > .title {
    h2 {
      color: #383A4D;
      font-size: 26px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 36px;
      margin: 0 0 16px;
    }
  }

  > .filter {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .countries {
      flex-basis: 260px;
      min-width: 260px;
      margin-right: 20px;
    }

    .input {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      box-sizing: border-box;
      height: 44px;
      border: 1px solid ${ ({ theme }) => theme.colors.main };
      border-radius: 22px;
      padding: 0 12px;
      position: relative;

      input {
        flex: 1;
        height: 100%;
        border: none;
        margin: 0 10px;
        outline: none;
        color: #383A4D;
        font-size: 16px;
        letter-spacing: 0;
        background: transparent;
      }

      button {
        border: none;
        background-color: white;
        padding: 0;
        cursor: pointer;
      }
    }

    @media screen and (max-width: 500px) {
      .countries {
        flex-basis: 200px;
        min-width: 200px;
        margin-right: 20px;
      }
    }

    @media screen and (max-width: 500px) {
      flex-direction: column;

      .countries {
        flex-basis: auto;
        width: 100%;
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
  }
`
