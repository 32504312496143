import React from 'react'
import { Link } from 'gatsby-plugin-react-intl'
import { IOption } from '../../../../models'
import StyledLi from './styles'

interface IProps {
  option: IOption
  isHidden: boolean
}

export const Option: React.FC<IProps> = ({ option, isHidden }) => {
  const { region, city, address } = option

  if (isHidden) return null
  return (
    <StyledLi>
      <Link to={ `/${ region }/${ city }/${ address }/index.html` }>
          <span className="label">
            { option.label }
          </span>
      </Link>
    </StyledLi>
  )
}
