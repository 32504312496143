import React from 'react'
import { Link, useIntl } from 'gatsby-plugin-react-intl'
import { IStore } from '../../../models'
import StyledDiv from './styles'
import { OpeningHours } from './OpeningHours'

interface IProps {
  store: IStore
}

export const StoreCard: React.FC<IProps> = ({ store }) => {
  const { formatMessage } = useIntl()
  const { region, locality, adressLines } = store.cleanedurlparts

  const coverImage = (): string => {
    if (store && store.images) {
      const cover = store.images.find(image => image.type === 'COVER')
      if (cover) {
        return `url(${ cover.url })`
      } else {
        return ''
      }
    } else {
      return ''
    }
  }

  return (
    <StyledDiv>
      <header>
        {
          coverImage()
            ? <div className="cover" style={{ backgroundImage: coverImage() }} />
            : null
        }
        <h3>{ store.locationName }</h3>
      </header>
      <p className="address">
        { store.addressLines }
      </p>
      <OpeningHours regularHours={ store.regularHours }/>
      <div className="contact">
        <h4>{ formatMessage({ id: 'contactInformation' }) }</h4>
        <p className="phone">
          <a href={ `tel:${ store.primaryPhone }` }>{ store.primaryPhone }</a>
        </p>
      </div>
      <footer>
        <Link to={ `/${ region }/${ locality }/${ adressLines }/index.html` }>
          { formatMessage({ id: 'moreDetails' }) }
        </Link>
      </footer>
    </StyledDiv>
  )
}