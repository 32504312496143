import styled from 'styled-components'

export default styled.section`
  .title {
    color: #383A4D;
    font-size: 26px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 36px;
    margin: 0 0 16px;
  }

  .text {
    color: #383A4D;
    font-size: 16px;
    letter-spacing: 0;
    margin: 0 0 60px;
    font-weight: normal;
  }
`
