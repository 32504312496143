// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-city-tsx": () => import("./../../../src/templates/city.tsx" /* webpackChunkName: "component---src-templates-city-tsx" */),
  "component---src-templates-region-tsx": () => import("./../../../src/templates/region.tsx" /* webpackChunkName: "component---src-templates-region-tsx" */),
  "component---src-templates-store-tsx": () => import("./../../../src/templates/store.tsx" /* webpackChunkName: "component---src-templates-store-tsx" */)
}

