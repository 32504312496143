import React from 'react'
import { useIntl } from 'gatsby-plugin-react-intl'
import StyledArticle from './styles'
import classNames from 'classnames'

interface IProps {
  address: string
  locality: string
  region: string
  phone: string
  latitude: number
  longitude: number
  isStoreOpen?: boolean
  websiteUrl: string
}
export const ContactCard: React.FC<IProps> = ({
  address, locality, region, phone, latitude, longitude, isStoreOpen, websiteUrl
}) => {
  const { formatMessage } = useIntl()

  return (
    <StyledArticle>
      <section className="columns">
        <div className="col">
          <header>
            <h2>{formatMessage({ id: 'address' })}</h2>
          </header>
          <p>{address}</p>
          <p>{locality}</p>
          <p>{region}</p>
          <p className={classNames('store-status', { open: isStoreOpen, closed: !isStoreOpen })}>
            <img src={`/images/ic-clock-${isStoreOpen ? 'green' : 'red'}.svg`} alt="Clock" />
            {formatMessage({ id: isStoreOpen ? 'open' : 'closed' })}
          </p>
        </div>
        <div className="col">
          <header>
            <h2>{formatMessage({ id: 'contactInformation' })}</h2>
          </header>
          <p className="phone"><a href={`tel:${phone}`}>{phone}</a></p>


          <gothaersadlink>
            <a
              target="_blank"
              href={`${websiteUrl}`}
            >
              {formatMessage({ id: 'detailtext' })}
            </a></gothaersadlink>


        </div>
      </section>
      <footer>
        <a
          target="_blank"
          href={`https://www.google.com/maps/dir/Current+Location/${latitude},${longitude}`}
        >
          {formatMessage({ id: 'directions' })}
        </a>
      </footer>
    </StyledArticle>
  )
}
