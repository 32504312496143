import styled from 'styled-components'

export default styled.main`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-x: hidden;

  > .header {
    padding: 20px 0;
    box-sizing: border-box;
    background-color: ${ ({ theme }) => theme.colors.light };
    /* color: white; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .logo {
      
      max-width: 1140px;
      margin: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      img {
        max-width: 200px;
        height: auto;
      }

      > div.hamburger-menu{
        width: 120px;

        .lines {
          display: none;
        }

        @media screen and (max-width: 768px) {
          width: 76px;

          .lines {
            display: block;
            width: 24px;
            cursor: pointer;

            .line {
              display: block;
              height: 2px;
              width: 24px;
              background-color: ${ ({ theme }) => theme.colors.main };
              margin-bottom: 6px;
            }

          }

        }
      }
      
    }

    .navigation {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 40px 0 0;

      div {
        flex: 1 0 auto;
        margin: 0 30px;
        
        a {
          text-decoration: none;
          color: ${ ({ theme }) => theme.colors.main };
        }
      }

      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    .navigation-small {
      display: none;

      @media screen and (max-width: 768px) {
        display: none;

        &.visible {
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: white;
          z-index: 9000;
          box-sizing: border-box;
          padding: 32px 16px;
        }

        .close {
          width: 24px;
          height: 24px;
          cursor: pointer;
          position: relative;

          .line {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            margin: auto;
            height: 2px;
            width: 24px;
            display: block;
            background-color: ${ ({ theme }) => theme.colors.main };

            &:first-of-type {
              transform: rotate(45deg);
            }
            &:last-of-type {
              transform: rotate(-45deg);
            }
          }
        }

        .links {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .header-link {
            margin-bottom: 60px;

            a {
              text-decoration: none;
              color: ${ ({ theme }) => theme.colors.main };
              font-size: 18px;
              font-weight: 600;
              letter-spacing: 0;
            }

            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    @media screen and (max-width: 1139px) {
      padding: 24px 16px;
    }
  }

  > .breadcrumb {
    /* padding: 22px 0; */
    height: 68px;
    border-bottom: 1px solid #E3E4F3;
    max-width: 1140px;
    width: 100%;
    margin: 40px auto 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;

    @media screen and (max-width: 1139px) {
      margin: 40px 16px 20px;
    }

    .breadcrumb__item {
      color: #656883;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 24px;
    }

    .breadcrumb__separator {
      margin: 0 
    }
  }

  > .content {
    max-width: 1140px;
    margin: auto;
    width: 100%;
    box-sizing: border-box;

    @media screen and (max-width: 1139px) {
      padding: 0 16px;
    }

    .map {
      width: 100%;
      border-radius: 10px;
    }
  }

  > .footer {
    background-color: #383A4D;
    color: white;

    .columns {
      padding: 60px 150px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;

      div {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        h4 {
          color: #A3A7C0;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 17px;
          font-weight: normal;
          margin: 0 0 10px;
        }

        a {
          color: white;
          text-decoration: none;
        }

        ul {
          padding: 0;
          margin: 0;
          list-style: none;

          li {
            color: #FFFFFF;
            font-size: 14px;
            letter-spacing: 0;
            margin-bottom: 6px;

            a {
              color: white;
              text-decoration: none;
            }
          }
        }

        p {
          color: #FFFFFF;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 19px;
          margin: 0;
        }
      }
    }

    .footerRow {
      padding: 22px 150px;
      box-sizing: border-box;
      background-color: #1E202C;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      nav {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        div {
          height: 24px;
          padding: 0 16px;
          border-left: 1px solid #656883;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          &:first-of-type {
            padding-left: 0;
            border-left: none;
          }

          a {
            color: #FFFFFF;
            font-size: 14px;
            letter-spacing: 0;
            text-decoration: none;
          }
        }

        > a {
          width: 24px;
          margin-left: 40px;
        }
      }
    }

    @media screen and (max-width: 1139px) {
      .columns {
        padding: 60px 32px;
        flex-direction: row;
        flex-wrap: wrap;

        div {
          max-width: calc(100% / 3);
          margin-bottom: 40px;
          margin-right: 8px;
          margin-left: 8px;

          &.text {
            width: 100%;
            flex: 1 1 auto;
            margin-bottom: 0;
          }
        }
      }

      .footerRow {
        padding: 20px 16px;
        flex-direction: column;
        flex-wrap: wrap;

        nav {
          width: 100%;
          justify-content: center;

          &:last-of-type:not(:empty) {
            margin-top: 32px;
          }

          div {
            padding: 0 8px;

            a {
              font-size: 12px;
              text-align: center;
            }
          }

          > a {
            &:first-of-type {
              margin-left: 0;
            }
          }
        }
      }
    }

    @media screen and (max-width: 500px) {
      .columns {
        padding: 60px 40px;
        flex-direction: row;
        flex-wrap: wrap;

        div {
          flex-basis: calc(100% / 3);
          margin-bottom: 40px;

          &.text {
            width: 100%;
            max-width: 100%;
            flex: 1 1 auto;
            margin-bottom: 0;
          }
        }
      }

      .footerRow {
        padding: 20px 16px;
        flex-direction: column;
        flex-wrap: wrap;

        nav {
          width: 100%;
          justify-content: center;

          &:last-of-type:not(:empty) {
            margin-top: 32px;
          }

          div {
            padding: 0 8px;

            a {
              font-size: 12px;
              color: #fff;
              text-align: center;
            }
          }

          > a {
            &:first-of-type {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
`
