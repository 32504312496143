import { ISourceStore, IStore } from '../models'
import storesFeed from '../../storefeed.json'

export const sourceStores: ISourceStore[] = JSON.parse(JSON.stringify(storesFeed))

export const storeToMarker = (
  store: IStore,
  map: google.maps.Map,
  infoWindow: google.maps.InfoWindow,
  locale: string,
  icon: string
): google.maps.Marker => {
  const lat = store.geocodedLat
  const lng = store.geocodedLng
  const { region, locality, adressLines } = store.cleanedurlparts
  const marker = new google.maps.Marker({
    position: { lat, lng },
    icon,
    map
  })

  marker.addListener('click', () => {
    infoWindow.setContent(`<a href="${location.origin}/${locale}/${region}/${locality}/${adressLines}/index.html">
      ${store.locationName}<br />
      ${store.addressLines}<br />
      ${store.geocodedAdministrativeAreaLevel1}
    </a>`)
    infoWindow.setPosition({ lat, lng })
    infoWindow.setOptions({ pixelOffset: new google.maps.Size(0, -30) })
    infoWindow.open(map)
  })

  return marker
}
