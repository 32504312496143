import React from 'react'
import { IOption } from '../../../../models'
import { Option } from '../Option'
import StyledSection from './styles'

interface IProps {
  query: string
  options: IOption[]
  isHidden: boolean
}

export const Dropdown: React.FC<IProps> = ({ query, options, isHidden }) => {
  const isFiltered = (item: string) => {
    return item?.toLowerCase().includes(query.toLowerCase())
  }

  const optionItems = options
    ? options.map(option => (
      <Option
        key={ `dropdown-option-${ option.id }` }
        option={ option }
        isHidden={ !isFiltered(option.label) }
      />
    )) : []

  if (isHidden || !options.some(o => isFiltered(o.label))) return null
  return (
    <StyledSection className="options">
      <ul>
        { optionItems }
      </ul>
    </StyledSection>
  )
}
