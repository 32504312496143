import classNames from 'classnames'
import React, { ReactNode } from 'react'
import brandConfig from '../../brand.json'
import { IHomeText } from '../models'

export const getMainColor = (): string => {
  return brandConfig.mainColor
}

export const getMainLightColor = (): string => {
  return brandConfig.mainLight
}

export const getMainDarkColor = (): string => {
  return brandConfig.mainDark
}

export const getLogo = (): string => {
  return brandConfig.logo
}

export const getFavicon = (): string => {
  return brandConfig.favicon
}

export const getBrand = (): string => {
  return brandConfig.brand
}

export const getUrl = (): string => {
  return brandConfig.url
}

export const getPriceRange = (): string => {
  return brandConfig.priceRange
}

export const getHeaderLinks = (): ReactNode => {
  return brandConfig.headerLinks.length
    ? brandConfig.headerLinks.map(link => (
      <div key={`header-${ link.name }`} className="header-link">
        <a href={ link.link } target="_blank">{ link.name }</a>
      </div>
    )) : null
}

export const getFooterLinks = (): ReactNode => {
  return brandConfig.footerLinks.length
    ? brandConfig.footerLinks.map(link => (
      <div key={`footer-${ link.name }`}>
        <a href={ link.link } target="_blank">{ link.name }</a>
      </div>
    )) : null
}

export const getSocialLinks = (): ReactNode => {
  const { twitter, facebook, instagram, youtube, gitlab } = brandConfig.socialLinks

  if (twitter || facebook || instagram || youtube || gitlab) {
    const twitterLink = twitter ? (
      <a href={ twitter }>
        <img src={ `/images/ic-twitter.svg` } alt="Twitter" width={24} height={24} />
      </a>
    ) : null
    const fbLink = facebook ? (
      <a href={ facebook }>
        <img src={ `/images/ic-facebook.svg` } alt="Facebook" width={24} height={24} />
      </a>
    ) : null
    const instagramLink = instagram ? (
      <a href={ instagram }>
        <img src={ `/images/ic-instagram.svg` } alt="Instagram" width={24} height={24} />
      </a>
    ) : null
    const youtubeLink = youtube ? (
      <a href={ youtube }>
        <img src={ `/images/ic-youtube.svg` } alt="Youtube" width={24} height={24} />
      </a>
    ) : null
    const gitlabLink = gitlab ? (
      <a href={ gitlab }>
        <img src={ `/images/ic-gitlab.svg` } alt="Gitlab" width={24} height={24} />
      </a>
    ) : null

    return (
      <>
        { twitterLink }
        { fbLink }
        { instagramLink }
        { youtubeLink }
        { gitlabLink }
      </>
    )
  } else {
    return null
  }
}

export const getFooterColumns = (): ReactNode => {
  return brandConfig.footerColumns.length
    ? brandConfig.footerColumns.map(column => (
      <div className={ classNames({ text: !!column.text }) }>
        <h4>{ column.title }</h4>
        { column.text ? (<p>{ column.text }</p>) : null }
        { column.textlink ? (<a id={`ratgeber`} href={ column.textlink }>{ column.textlinktitle }</a>) : null }
        <ul>
          {
            column.links && column.links.length
              ? column.links.map(l => (
                <li>
                  <a href={ l.link }>{ l.name }</a>
                </li>
              )) : null
          }
        </ul>
      </div>
    ))
    : null
}

export const getHomeTextContent = (): IHomeText => {
  return brandConfig.homeText
}
