import styled from 'styled-components'

export default styled.div`
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 20px;

  > a {
    color: unset;
    text-decoration: none;

    span {
      color: #656883;
      font-size: 16px;
      letter-spacing: 0;
      font-weight: normal;

      .count {
        color: #A3A7C0;
        font-weight: normal;
      }
    }
  }
`