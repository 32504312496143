import styled from 'styled-components'

export default styled.div`
  box-sizing: border-box;
  padding: 40px;
  background-color: ${ ({ theme }) => theme.colors.light };
  border-radius: 10px;
  flex: 1;

  header {
    margin-bottom: 20px;
    h3 {
      color: #383A4D;
      font-size: 22px;
      font-weight: bold;
      letter-spacing: 0;
      margin: 0;
    }
  }

  > ul {
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    box-sizing: border-box;
    margin: 0;

    li {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 20px 0;

      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
      
      span {
        color: #383A4D;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;
      }
    }
  }
`