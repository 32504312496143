import { IOption } from '../models'

export const availableLanguages: IOption[] = [
  { id: 'de-de', label: 'de-de' },
  { id: 'de-ch', label: 'de-ch' },
  { id: 'de-at', label: 'de-at' }
] 

export const getAvailableLanguages = (countryFilter: string) => {
  const english = { id: 'en-gb', label: 'en-gb' }
  const countryLanguages = availableLanguages.filter(lang => {
    const [languageCode, countryCode] = lang.id.split('-')
    return countryCode === countryFilter.toLowerCase()
  })
  return [english, ...countryLanguages]
}
