import styled from 'styled-components'

export default styled.section`
  width: 100%;
  height: 370px;
  margin-bottom: 60px;

  .map {
    height: 100%;
    width: 100%;
  }
`
