import { MarkerClusterer } from '@googlemaps/markerclusterer'
import { useIntl } from 'gatsby-plugin-react-intl'
import React, { useEffect, useRef } from 'react'
import { storeToMarker } from '../../../helpers'
import { IStore } from '../../../models'
import StyledSection from './styles'

interface IProps {
  stores: IStore[]
}

export const Map: React.FC<IProps> = ({ stores }) => {
  const { locale } = useIntl()
  const mapRef = useRef<HTMLDivElement>(null)
  let map!: google.maps.Map
  let infoWindow!: google.maps.InfoWindow
  let markers!: google.maps.Marker[]
  let bounds!: google.maps.LatLngBounds
  let clusterer!: MarkerClusterer

  const drawMap = (): void => {
    if (mapRef && mapRef.current) {
      map = new google.maps.Map(mapRef.current, {
        zoom: 16,
        fullscreenControl: false,
        mapTypeControl: false,
        scrollwheel: false,
        rotateControl: true,
        scaleControl: false,
        streetViewControl: false
      })
      infoWindow = new google.maps.InfoWindow()
      markers = stores.map(store => storeToMarker(store, map, infoWindow, locale, `/images/pin-map.svg`))
      clusterer = new MarkerClusterer({ map, markers })
      if (markers.length === 1) {
        const position = markers[0].getPosition()
        if (position) map.setCenter(position)
      } else {
        bounds = new google.maps.LatLngBounds()
        markers.forEach((marker: google.maps.Marker) => {
          const point = marker.getPosition()
          if (point) bounds.extend(point)
        })
        map.fitBounds(bounds)
      }
    }
  }

  useEffect(() => {
    drawMap()
  }, [stores])

  return (
    <StyledSection className="container">
      <div className="map" id="map" ref={ mapRef ?? undefined } />
    </StyledSection>
  )
}
