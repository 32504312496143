import { ICleanedUrl, IOption, ISourceStore, IStore, IStorePeriod } from '../models'
import { weekDays } from './date'

export const mapSourceStoreToStoreObject = (source: ISourceStore): IStore => {
  return {
    ...source,
    images: source.images ? source.images.map(image => ({ url: image.url, type: image.Type })) : [],
    geocodedAdministrativeAreaLevel1: source.geocoded_administrative_area_level_1,
    geocodedLat: source.geocoded_lat,
    geocodedLng: source.geocoded_lng,
    openForBusiness: source.open_for_business,
    temporaryClosed: source.temporary_closed
  }
}

export const getCountries = (stores: IStore[]): IOption[] => {
  const countryCodes = Array.from(new Set(stores.map(s => s.regionCode)))
  return countryCodes.map(code => ({ id: code, label: code, icon: code }))
}

export const getCities = (stores: IStore[]): string[] => {
  const cities = Array.from(new Set(stores.map(store => store.locality)))
  return cities.sort((prev, next) => prev > next ? 1 : -1)
}

export const getCitiesInitials = (stores: IStore[]): string[] => {
  const cities = Array.from(new Set(stores.map(store => store.locality)))
  return Array.from(new Set(cities.map(c => c.charAt(0)))).sort((prev, next) => prev > next ? 1 : -1)
}

export const getRegions = (stores: IStore[]): string[] => {
  const regions = Array.from(new Set(stores.map(store => store.geocodedAdministrativeAreaLevel1)))
  return regions.sort((prev, next) => prev > next ? 1 : -1)
}

export const getRegionsInitials = (stores: IStore[]): string[] => {
  const regions = Array.from(new Set(stores.map(store => store.geocodedAdministrativeAreaLevel1)))
  return Array.from(new Set(regions.map(c => c.charAt(0)))).sort((prev, next) => prev > next ? 1 : -1)
}

export const getRegionStoresCount = (stores: IStore[], region: string): number => {
  const filteredStores = stores.filter(store => store.geocodedAdministrativeAreaLevel1 === region)
  return filteredStores.length
}

export const getCityStoresCount = (stores: IStore[], city: string): number => {
  const filteredStores = stores.filter(store => store.locality === city)
  return filteredStores.length
}

export const getExistingStoresAttributes = (stores: IStore[]): string[] => {
  const attributes = Array.from(new Set(stores.flatMap(store => store.attributes)))
  return attributes
}

export const containRegionStoreWithAttributes = (stores: IStore[], region: string, attributes: string[]): boolean => {
  const filteredStores = stores.filter(store => store.geocodedAdministrativeAreaLevel1 === region)
  return filteredStores.some(store => attributes.every(att => store.attributes.includes(att)))
}

export const containCityStoreWithAttributes = (stores: IStore[], city: string, attributes: string[]): boolean => {
  const filteredStores = stores.filter(store => store.locality === city)
  return filteredStores.some(store => attributes.every(att => store.attributes.includes(att)))
}

export const getCityRegion = (stores: IStore[], city: string): string | undefined => {
  const store = stores.find(store => store.locality === city)
  return store ? store.geocodedAdministrativeAreaLevel1 : undefined
}

export const getCleanedUrl = (stores:IStore[], location: string, isRegion: boolean): ICleanedUrl => {
  const store = stores
    .find(store => isRegion ? store.geocodedAdministrativeAreaLevel1 === location : store.locality === location )
  return store ? store.cleanedurlparts : { region: '', locality: '', adressLines: '' }
}

export const isStoreOpen = (periods: IStorePeriod[]): boolean => {
  const today = new Date()
  const compareOpenDate = new Date()
  const compareCloseDate = new Date()
  const todayDay = weekDays[today.getDay()]
  const filteredPeriods = periods.filter(period => period.openDay.toLowerCase() === todayDay)

  if (filteredPeriods.length) {
    return filteredPeriods.some(p => {
      const [openHour, openMinutes] = p.openTime.split(':')
      const [closeHour, closeMinutes] = p.closeTime.split(':')
      const openTime = compareOpenDate.setHours(Number(openHour), Number(openMinutes))
      const closeTime = compareCloseDate.setHours(Number(closeHour), Number(closeMinutes))
      return openTime < today.getTime() && today.getTime() < closeTime
    })
  } else {
    return false
  }
}

export const formatOpeningHoursToLocalBusinessJSONSchema = (periods: IStorePeriod[]): string => {
  return periods
    .map(period => {
      const day = period.openDay.slice(0,2)
      return `${ day } ${ period.openTime }-${ period.closeTime }`
    })
    .join(' ')
    .trim()
}
