import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from 'styled-components'
import { Link, changeLocale, useIntl } from 'gatsby-plugin-react-intl'
import { LanguageSelector } from './LanguageSelector'
import { IBreadcrumbItem, IOption } from '../../models'
import {
  getAvailableLanguages,
  getBrand,
  getFavicon,
  getFooterColumns,
  getFooterLinks,
  getHeaderLinks,
  getLogo,
  getSocialLinks
} from '../../helpers'
import StyledMain from './styles'
import { theme } from '../../theme'
import classNames from 'classnames'

interface IProps {
  breadCrumbItems: IBreadcrumbItem[]
  countryFilter: string
}

export const Layout: React.FC<IProps> = ({ breadCrumbItems, countryFilter, children }) => {
  const { locale } = useIntl()
  const [selectedLanguage, setSelectedLanguage] = useState<IOption>({ id: locale, label: locale })
  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const languages = getAvailableLanguages(countryFilter)
  const headerLinks = getHeaderLinks()
  const footerColumns = getFooterColumns()
  const footerLinks = getFooterLinks()
  const socialLinks = getSocialLinks()

  const breadcrumb =  () => {
    if (breadCrumbItems) {
      const lastItem = breadCrumbItems.pop()
      const links = breadCrumbItems.map(item => (
        <Link key={ item.label } className="breadcrumb__item" to={ item.link }>
          { item.label }
        </Link>
      ))
      return links.map(link => (
        <>
          { link }
          <img className="breadcrumb__separator" src={ `/images/ic-chevron-right.svg` } width={ 24 } height={ 24 }/>
        </>
      )).concat(<span className="breadcrumb__item">{ lastItem?.label }</span>)
    } else {
      return null
    }
  }

  const handleLanguageChange = (option: IOption) => {
    setSelectedLanguage(option)
    changeLocale(option.id)
  }

  const toggleResponsiveMenu = () => setIsMenuVisible(current => !current)

  return (
    <ThemeProvider theme={ theme }>
      <StyledMain>
        <Helmet>
          <title>Gothaer Beraterfinder</title>
          <meta name="description" content="Gothaer Beraterfinder" />
          <link rel="icon" href={ getFavicon() } />
          <link rel="stylesheet" href="https://cdn.consentmanager.mgr.consensu.org/delivery/cmp.min.css" />
        </Helmet>
        <header className="header">
          <section className="logo">
            <div className="hamburger-menu">
              <div className="lines" onClick={ toggleResponsiveMenu }>
                <span className="line" />
                <span className="line" />
                <span className="line" />
              </div>
            </div>
            <Link to="/">
              <img src={ getLogo() } alt={ getBrand() } />
            </Link>
            
            <LanguageSelector
              labelProp="label"
              value={ selectedLanguage }
              options={ languages }
              onChange={ handleLanguageChange }
            />
            
          </section>
          {/* Generate from Store DATA */}
          <nav className="navigation">
            { headerLinks }
          </nav>
          <nav className={ classNames('navigation-small', { visible: isMenuVisible }) }>
            <div className="close" onClick={ toggleResponsiveMenu }>
              <span className="line" />
              <span className="line" />
            </div>
            <div className="links">
              { headerLinks }
            </div>
          </nav>
        </header>
        <nav className="breadcrumb">
          { breadcrumb() }
        </nav>
        <article className="content">
          {/* <Breadcrumbs useDefaultStyle rootLabel='Home' /> */}
          { children }
        </article>
        {/* Generate from Store Data */}
        <footer className="footer">
          {
            footerColumns ? (
              <section className="columns">
                { footerColumns }
              </section>
            ) : null
          }
          {
            footerLinks || socialLinks
              ? (
                <section className="footerRow">
                  <nav>
                    { footerLinks }
                  </nav>
                  <nav>
                    { socialLinks }
                  </nav>
                </section>
              ) : null
          }
        </footer>
      </StyledMain>
    </ThemeProvider>
  )
}
