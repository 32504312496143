import classNames from 'classnames'
import { useIntl } from 'gatsby-plugin-react-intl'
import React from 'react'
import StyledLi from './styles'

interface IProps {
  label: string
  isSelected: boolean
  onClick: () => void
}

export const Option: React.FC<IProps> = ({ label, isSelected, onClick }) => {
  const { formatMessage } = useIntl()
  return (
    <StyledLi className={ classNames({ selected: isSelected }) } onClick={ onClick }>
      <span className="label">{ formatMessage({ id: label }) }</span>
      {
        isSelected 
          ? <img src={ `/images/ic-check.svg` } width={ 24 } height={ 24 } />
          : null
      }
    </StyledLi>
  )
}
