import React, { useEffect, useState } from "react"
import { useIntl, changeLocale } from 'gatsby-plugin-react-intl'
import { IOption } from '../models'
import { Layout } from '../containers'
import { Map, StoreSearch, TextBlock, StoreFilters } from '../components'
import {
  availableLanguages,
  getCountries,
  getHomeTextContent,
  mapSourceStoreToStoreObject,
  sourceStores
} from '../helpers'

// markup
const IndexPage: React.FC = () => {
  const { formatMessage, locale } = useIntl()
  const [countryFilter, setCountryFilter] = useState<IOption>({ id: 'DE', label: 'DE', icon: 'DE' })
  const stores = sourceStores.map(s => mapSourceStoreToStoreObject(s))
  const storesFiltered = countryFilter
    ? stores.filter(s => s.regionCode === countryFilter.id)
    : sourceStores.map(s => mapSourceStoreToStoreObject(s))
  const { title, text } = getHomeTextContent()
  const countries =  getCountries(stores)

  const handleFilterCountry = (country: IOption) => {
    setCountryFilter(country)
    const existsCountryLanguage = availableLanguages.find(lang => {
      const [languageCode, countryCode] = lang.id.split('-')
      return countryCode === country.id.toLowerCase()
    })
    if (existsCountryLanguage && !(locale as string).includes(country.id.toLowerCase())) {
      changeLocale(existsCountryLanguage.id)
    } else {
      changeLocale('en-gb')
    }
  }

  useEffect(() => {
    const [localeLang, localeCountry] = locale.split('-')
    if (localeCountry !== countryFilter.id.toLowerCase()) {
      setCountryFilter(current => {
        const country = countries.find(c => c.id === localeCountry.toUpperCase())
        return country ?? current
      })
    }
  }, [])

  return (
    <Layout
      key={ `Home-${ locale }` }
      countryFilter={ countryFilter.id }
      breadCrumbItems={ [{ label: formatMessage({ id: 'home' }), link: '/' }] }
    >
      <section className="map">
        <Map stores={ storesFiltered } />
      </section>
      <StoreSearch
        items={ storesFiltered }
        countries={ countries }
        selectedCountry={ countryFilter }
        onCountryChange={ handleFilterCountry }
      />
      <TextBlock
        title={ title }
        // eslint-disable-next-line max-len
        text={ text }
      />
      <StoreFilters stores={ storesFiltered }/>
      {/* Async script executes immediately and must be after any DOM elements used in callback. */}
    </Layout>
  )
}

export default IndexPage
