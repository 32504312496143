import React from 'react'
import StyledSection from './styles'

interface IProps {
  title: string
  text: string
}

export const TextBlock: React.FC<IProps> = ({ title, text }) => {
  if (!(title || text)) return null
  return (
    <StyledSection>
      <h2 className="title">{ title }</h2>
      <p className="text">{ text }</p>
    </StyledSection>
  )
}
