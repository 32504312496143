import styled from 'styled-components'

export default styled.article`
  min-width: 636px;
  flex-basis: 636px;
  box-sizing: border-box;
  background-color: #FFFFFF;
  box-shadow: 0 4px 20px 0 rgba(0,0,0,0.1);
  border-radius: 10px;
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;

  .columns {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;

    .col {
      box-sizing: border-box;
      margin: 40px 0;
      padding: 0 40px;
      flex: 1;

      &:first-of-type {
        border-right: 1px solid #E3E4F3;
      }

      header {
        margin-bottom: 20px;

        h2 {
          margin: 0;
          color: #000000;
          font-size: 22px;
          font-weight: bold;
          letter-spacing: 0;
        }
      }

      p {
        color: #000000;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;
        margin: 0 0 12px;

        &.phone {
          a {
            color: ${ ({ theme }) => theme.colors.main };
          }
        }

        &.store-status {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          font-weight: normal;
          margin-top: 22px;

          &.open {
            color: #66CD56;
          }

          &.closed {
            color: #FF7373;
          }

          img {
            margin-right: 8px;
            width: 24px;
            height: auto;
          }
        }
      }
    }
  }

  footer {
    width: 100%;
    box-sizing: border-box;
    padding: 0 40px 40px;

    a {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      height: 44px;
      width: 100%;
      border: 1px solid ${ ({ theme }) => theme.colors.main };
      border-radius: 22px;
      background-color: ${ ({ theme }) => theme.colors.light };
      color: ${ ({ theme }) => theme.colors.main };
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 22px;
      text-align: center;
      cursor: pointer;
      text-decoration: none;

      &:active {
        border: 1px solid ${ ({ theme }) => theme.colors.dark };
        background-color: #E3E4F3;
        color: ${ ({ theme }) => theme.colors.dark };
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 22px;
        text-align: center;
      }
    }
  }


  gothaersadlink {
    width: 100%;
    box-sizing: border-box;
    padding: 0;

    a {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      height: 44px;
      width: 100%;
      border: 1px solid ${ ({ theme }) => theme.colors.main };
      border-radius: 22px;
      background-color: ${ ({ theme }) => theme.colors.light };
      color: ${ ({ theme }) => theme.colors.main };
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 22px;
      text-align: center;
      cursor: pointer;
      text-decoration: none;

      &:active {
        border: 1px solid ${ ({ theme }) => theme.colors.dark };
        background-color: #E3E4F3;
        color: ${ ({ theme }) => theme.colors.dark };
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 22px;
        text-align: center;
      }
    }
  }

  @media screen and (max-width: 768px) {
    min-width: 100%;
    flex-basis: 100%;
    margin-bottom: 60px;

    .columns {
      flex-direction: column;

      .col {
        margin: 40px 40px;
        padding: 0;

        &:first-of-type {
          border-right: none;
          border-bottom: 1px solid #E3E4F3;
          padding: 0 0 40px;
          margin-bottom: 0;
        }
      }
    }
  }
`
