import styled from 'styled-components'

export default styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: stretch;

  header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    overflow: hidden;

    .initials {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex: 1;
      width: calc(100% - 158px);
      box-sizing: border-box;
      margin-right: 28px;

      .prev,
      .next {
        padding: 0 12px;
        cursor: pointer;
      }
    
      .initials__container {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: nowrap;
        padding: 20px 0px;
        box-sizing: border-box;
        overflow-x: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          /* display: none; */
          visibility: hidden;
        }
      }

      .initial {
        box-sizing: border-box;
        height: 44px;
        min-width: 50px;
        flex-basis: 50px;
        max-width: 50px;
        border: 1px solid #656883;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 16px;
        cursor: pointer;
        transition: ease all 0.1s;

        &:last-of-type {
          margin-right: 0;
        }

        span {
          color: #656883;
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 22px;
          text-align: center;
          user-select: none;
          transition: ease all 0.1s;
        }

        &.active {
          background-color: #656883;
          
          span {
            color: white;
          }
        }
      }

      @media screen and (max-width: 1139px) {
        margin-right: 0;

        .prev {
          padding-left: 0;
        }
      }
    }

    .attributes__button {
      display: none;
      height: 44px;
      width: 130px;
      flex-basis: 130px;
      border-radius: 22px;
      border: none;
      background-color: ${ ({ theme }) => theme.colors.main };
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 24px;
      cursor: pointer;

      &:active {
        background-color: ${ ({ theme }) => theme.colors.dark };
      }

      img {
        margin-right: 12px;
      }

      @media screen and (max-width: 1139px) {
        font-size: 0;
        width: 44px;
        flex-basis: 44px;
        
        img {
          margin-right: 0;
        }
      }
    }
  }

  .attributes__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    border-radius: 10px;
    background-color: ${ ({ theme }) => theme.colors.light }99;
    padding: 20px 20px 4px;
    box-sizing: border-box;
    transition: ease all 0.2s;

    &:not(.visible) {
      height: 0;
      padding: 0 20px;

      .pill {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
`