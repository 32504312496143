import styled from 'styled-components'

export default styled.div`
  display: flex;
  position: relative;
  flex: 0 0 auto;
  width: 120px;
  height: 44px;
  background: transparent;
  color: black;
  align-items: center;
  padding: 0px 8px 0px 12px;
  cursor: pointer;
  border: 1px solid ${ ({ theme }) => theme.colors.main };
  border-radius: 22px;
  box-sizing: border-box;

  &:hover:not(.disabled) {
    cursor: pointer;
  }

  &.active {
    .arrow-icon {
      transform: rotate(180deg);
    }
  }

  .value {
    display: flex;
    flex: 1 1 0%;
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0 10px;
    background: transparent;
    border: none;
    box-shadow: none;
    pointer-events: none;
    color: ${ ({ theme }) => theme.colors.main };

    &::placeholder {
      color: grey;
    }

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    width: 76px;
    justify-content: space-between;
  }
`
