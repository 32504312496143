import classnames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'gatsby-plugin-react-intl'
import { isChildNode } from '../../../helpers'
import { IOption } from '../../../models'
import { Dropdown } from './Dropdown'
import { Option } from './Option'
import StyledDiv from './styles'

interface IProps {
  labelProp: string
  options: IOption[]
  value?: IOption
  placeholder?: string
  isDisabled?: boolean
  isInvalid?: boolean
  isHidden?: boolean
  form?: HTMLFormElement
  onChange: (value: IOption) => void
}

export const LanguageSelector: React.FC<IProps> = ({
  value, isHidden, isDisabled, isInvalid, options, labelProp, form, onChange, placeholder
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const { formatMessage } = useIntl()
  const [ isOpen, setIsOpen ] = useState(false)
  
  const isDisabledOrEmpty = isDisabled || !options || !!options && !options.length

  const handleChange = (option: IOption) => {
    // setSelected(option)
    onChange(option)
    setIsOpen(false)
  }

  const handleIconClick = (event: any) => {
    event.stopPropagation()
    setIsOpen(!isDisabledOrEmpty && !isOpen)
  }

  const items = options.map(x =>
    <Option
      key={ x.id }
      label={ x[labelProp] }
      isSelected={ value?.id === x.id }
      onClick={ () => handleChange(x) }
    />
  )

  const handleDocumentClick = (event: Event) => {
    if ((isOpen && !isChildNode(ref.current, event.target)) || ref.current !== event.target) setIsOpen(false)
  }

  useEffect(() => {
    // if (form) form.addEventListener('reset', setSelected.bind(undefined, initialValue ?? []))
    document.addEventListener('click', handleDocumentClick)

    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form])
  return null
  if (isHidden) return null
  return (
    <StyledDiv
      className={
        classnames({
          filled: value,
          active: isOpen,
          invalid: isInvalid,
          disabled: isDisabledOrEmpty
        })
      }
      onClick={ setIsOpen.bind(undefined, !isDisabledOrEmpty && !isOpen) }
      ref={ ref ?? undefined }
    >
      <img
        src={ `/images/ic-globe.svg` }
        width={ 20 }
        height={ 20 }
        onClick={ handleIconClick }
      />
      <input
        className="value"
        placeholder={ placeholder }
        readOnly
        value={ value ? formatMessage({ id: `${ value[labelProp] }-short` }) : '' }
      />
      <img 
        className="arrow-icon"
        src={ `/images/ic-chevron-down-white.svg` }
        width={ 24 }
        height={ 24 }
        onClick={ handleIconClick }
      />
      <Dropdown isHidden={ !isOpen || isDisabledOrEmpty }>
        { items }
      </Dropdown>
    </StyledDiv>
  )
}