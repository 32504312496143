import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { useIntl } from 'gatsby-plugin-react-intl'
import { IStoreRegularHours } from '../../../../models'
import StyledDiv from './styles'
import { isChildNode, isStoreOpen } from '../../../../helpers'

interface IProps {
  regularHours?: IStoreRegularHours
}

export const OpeningHours: React.FC<IProps> = ({ regularHours }) => {
  const { formatMessage } = useIntl()
  const ref = useRef<HTMLButtonElement>(null)
  const [ isOpen, setIsOpen ] = useState(false)
  const isStoreOpened = regularHours && regularHours.periods 
    ? isStoreOpen(regularHours.periods) 
    : false

  const days = regularHours && regularHours.periods 
    ? regularHours.periods.map(period => (
      <li key={ `${ period.openDay }-${ period.closeDay }-${ period.openTime }-${ period.closeTime }` }>
        <span>{ formatMessage({ id: period.openDay.toLowerCase() }) }</span>
        <span>{ period.openTime } - { period.closeTime }</span>
      </li>
    )) : null

  const handleDocumentClick = (event: Event) => {
    if ((isOpen && !isChildNode(ref.current, event.target)) || ref.current !== event.target) setIsOpen(false)
  }

  useEffect(() => {
    // if (form) form.addEventListener('reset', setSelected.bind(undefined, initialValue ?? []))
    document.addEventListener('click', handleDocumentClick)

    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!regularHours || !regularHours.periods) return null
  return (
    <StyledDiv>
      <button 
        ref={ ref ?? undefined } 
        className={ classNames('current-state', { open: isOpen, "store-open": isStoreOpened }) }
        onClick={() => setIsOpen(!isOpen)}
      >
        <img src={ `/images/ic-clock-${ isStoreOpened ? 'green' : 'red' }.svg` } alt="Clock" />
        { formatMessage({ id: isStoreOpened ? 'open' : 'closed' }) }
        <img src={ `/images/ic-chevron-${ isStoreOpened ? 'green' : 'red' }.svg` } alt="Arrow" className="arrow" />
      </button>
      { isOpen 
        ? (
          <ul className={ classNames({ open: isOpen }) }>{ days }</ul>
        ) : null 
      }
    </StyledDiv>
  )
}
