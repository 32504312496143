import styled from 'styled-components'

export default styled.div`
  border: 1px solid ${ ({ theme }) => theme.colors.main };
  border-radius: 10px;
  background-color: transparent;
  box-sizing: border-box;
  padding: 12px 20px;
  color: ${ ({ theme }) => theme.colors.main };
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0;
  margin-right: 8px;
  margin-bottom: 16px;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: ease all 0.1s;

  img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  &.selected {
    background-color: ${ ({ theme }) => theme.colors.main }33;
  }
`