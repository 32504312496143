import styled from 'styled-components'

export default styled.article`
  width: 100%;
  box-sizing: border-box;
  padding: 40px;
  border-radius: 10px;
  background-color: ${ ({ theme }) => theme.colors.light };
  margin-bottom: 60px;

  header {
    margin-bottom: 30px;

    h2 {
      margin: 0;
      color: #383A4D;
      font-size: 22px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 30px;
    }
  }

  .features {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    .feature {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: ${ ({ theme }) => theme.colors.main };
      font-size: 16px;
      letter-spacing: 0;
      line-height: 40px;
      margin-right: 40px;
      font-weight: normal;
      
      img {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
    }
  }
`