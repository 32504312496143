import styled from 'styled-components'

export default styled.article`
  width: 100%;
  border-radius: 10px;
  background-color: ${ ({ theme }) => theme.colors.light };
  box-sizing: border-box;
  padding: 40px 0;
  margin-bottom: 60px;

  > header {
    margin-bottom: 30px;
    padding: 0 40px;

    h2 {
      margin: 0;
      color: #383A4D;
      font-size: 22px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 30px;
    }
  }

  .dates {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    width: 100%;
    box-sizing: border-box;

    .prev,
    .next {
      padding: 0 6px;
      cursor: pointer;
    }

    .dates__container {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: nowrap;
      padding: 20px 8px;
      box-sizing: border-box;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      box-sizing: border-box;

      &::-webkit-scrollbar {
        /* display: none; */
        visibility: hidden;
      }

      .date {
        height: 290px;
        min-width: 258px;
        flex-basis: 258px;
        border-radius: 10px;
        background-color: #FFFFFF;
        box-shadow: 0 4px 20px 0 rgba(0,0,0,0.1);
        box-sizing: border-box;
        padding: 40px;
        margin: 0 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;

        header {
          h2 {
            color: #383A4D;
            font-family: "Open Sans";
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 24px;
            margin: 0 0 20px;
          }

          h3 {
            color: #383A4D;
            font-size: 16px;
            font-weight: normal;
            letter-spacing: 0;
            line-height: 22px;
            margin: 0;
          }
        }

        footer {
          margin-top: auto;

          p {
            margin: 0;

            &.opening-status {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              font-weight: normal;
              margin-top: 20px;

              &.open {
                color: #66CD56;
              }

              &.closed {
                color: #FF7373;
              }

              img {
                margin-right: 8px;
                width: 24px;
                height: auto;
              }
            }
          }
        }
      }
    }
  }
`