import styled from 'styled-components'

export default styled.li`
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  font-size: 16px;
  background: white;
  height: 44px;
  padding: 0 16px;
  overflow: hidden;
  align-items: center;
  cursor: pointer;
  color: #656883;
  box-sizing: border-box;
  border-bottom: 1px solid #E3E4F3;

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    background: whitesmoke;
    color: black;
  }

  &.selected {
    background: ${ ({ theme }) => theme.colors.light };
  }

  &.disabled {
    background: white;
    color: grey;
  }

  .label {
    /* text-overflow: ellipsis;
    overflow: hidden; */
    flex: 1;
  }
`