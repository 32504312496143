import styled from 'styled-components'

const StoreHeader = styled.header`
  width: 100%;
  margin-bottom: 26px;

  h2 {
    color: #383A4D;
    font-size: 26px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 36px;
    margin: 0;
  }
`

const StoreCover = styled.section`
  margin-bottom: 60px;

  img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
`

const RowSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 60px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const CardsSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 80px;
  box-sizing: border-box;

  h2 {
    color: #383A4D;
    font-size: 26px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 36px;
    margin: 0 0 26px;
  }

  .cards {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
  }
`

export { StoreHeader, StoreCover, CardsSection, RowSection }
