import React from 'react'
import classNames from 'classnames'
import StyledDiv from './styles'

interface IProps {
  label: string
  isSelected: boolean
  onClick: (label: string) => void
}

export const Pill: React.FC<IProps> = ({ label, isSelected, onClick }) => {
  return (
    <StyledDiv className={ classNames('pill', { selected: isSelected }) } onClick={ () => onClick(label) }>
      <img src={ `/images/${ label }.svg` } alt={ label } />
      { label }
    </StyledDiv>
  )
}
