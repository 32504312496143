import styled from 'styled-components'

export default styled.li`
  display: flex;
  flex-shrink: 0;
  height: 44px;
  flex-basis: 44px;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #E3E4F3;

  a {
    padding: 0 0 0 8px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: #656883;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    text-decoration: none;
  }

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    background-color: ${ ({ theme }) => theme.colors.light };
  }

  .label {
    font-size: 14px;
    pointer-events: none;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 0;
  }
`