import React from 'react'
import { useIntl } from 'gatsby-plugin-react-intl'
import StyledDiv from './styles'
import { IStoreRegularHours } from '../../../models'

interface IProps {
  regularHours?: IStoreRegularHours
}

export const OpeningHours: React.FC<IProps> = ({ regularHours }) => {
  const { formatMessage } = useIntl()

  const days = regularHours && regularHours.periods 
    ? regularHours.periods.map(period => (
      <li key={ `${ period.openDay }-${ period.closeDay }-${ period.openTime }-${ period.closeTime }` }>
        <span>{ formatMessage({ id: period.openDay.toLowerCase() }) }</span>
        <span>{ period.openTime } - { period.closeTime }</span>
      </li>
    )) : null

  return (
    <StyledDiv>
      <header>
        <h3>{ formatMessage({ id: 'openingHours' }) }</h3>
      </header>
      <ul>
        { days }
      </ul>
    </StyledDiv>
  )
}
