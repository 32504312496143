import { PageProps } from 'gatsby'
import React, { ReactNode } from 'react'
import { useIntl } from 'gatsby-plugin-react-intl'
import { LocalBusiness } from 'schema-dts'
import { helmetJsonLdProp } from 'react-schemaorg'
import { Layout } from '../containers'
import {
  ContactCard,
  ImageSlider,
  Map,
  OpeningHours,
  SpecialDates,
  StoreCard,
  StoreProperties,
  TextBlock
} from '../components'
import { IBreadcrumbItem, IPageContext, ISourceStore, IStore, IStoreImage, IStorePageContent } from '../models'
import {
  formatOpeningHoursToLocalBusinessJSONSchema,
  getBrand,
  getFavicon,
  getPriceRange,
  getUrl,
  isStoreOpen,
  mapSourceStoreToStoreObject
} from '../helpers'
import { CardsSection, RowSection, StoreCover, StoreHeader } from './styles'
import specialDates from '../../specialHourPeriods.json'
import { Helmet } from 'react-helmet'

const Store = (props: PageProps) => {
  const { formatMessage } = useIntl()
  const { pageContent } = props.pageContext as IPageContext<IStorePageContent>
  const { region, city, store, nearbyStores } = pageContent
  const detailStore = mapSourceStoreToStoreObject(store)
  const isStoreCurrentlyOpen = detailStore.regularHours && detailStore.regularHours.periods
    ? isStoreOpen(detailStore.regularHours.periods)
    : false

  const breadCrumbItems: IBreadcrumbItem[] = [
    { label: formatMessage({ id: 'home' }), link: '/' },
    region,
    city,
    {
      label: store.locationName,
      link:
        `/${ store.cleanedurlparts.region }/${ store.cleanedurlparts.locality }/${ store.cleanedurlparts.adressLines }`
    }
  ]

  const coverImage = (): ReactNode => {
    if (detailStore && detailStore.images) {
      const cover = detailStore.images.find((image: IStoreImage) => image.type === 'COVER')
      if (cover) {
        return <img src={ cover.url } alt="Cover" />
      } else {
        return null
      }
    } else {
      return null
    }
  }

  const sliderImages: string[] = detailStore && detailStore.images
    ? detailStore.images
      .filter(img => img.type === 'SLIDER')
      .map(img => img.url)
    : []

  const cards = nearbyStores
    .map((s: ISourceStore) => mapSourceStoreToStoreObject(s))
    .map((store: IStore) => (
      <StoreCard key={ store.locationName } store={ store } />
    ))

  return (
    <Layout
      key={`store-${ detailStore.locationName }`}
      countryFilter={ detailStore.regionCode }
      breadCrumbItems={ breadCrumbItems }
    >
      <Helmet script={[
        helmetJsonLdProp<LocalBusiness>({
            "@context": "https://schema.org",
            '@type': 'LocalBusiness',
            'name': getBrand(),
            'url': getUrl(),
            'image': [
              getFavicon()
            ],

            'description': detailStore.locationName,
            'address': {
            '@type': 'PostalAddress',
            'streetAddress': detailStore.addressLines,
            'addressLocality': detailStore.locality,
            'postalCode': detailStore.postalCode,
            'addressCountry': detailStore.regionCode,
            'telephone': detailStore.primaryPhone
            },
            'priceRange' : getPriceRange(),
            'contactPoint': {
              '@type': 'ContactPoint',
              'telephone': detailStore.primaryPhone,
              'contactType': 'customer service'
            },
            'geo': {
            '@type': 'GeoCoordinates',
            'latitude': detailStore.geocodedLat,
            'longitude': detailStore.geocodedLng
            },

            'hasMap': `https://www.google.com/maps/place/${ detailStore.locationName }/@${ detailStore.geocodedLat },${ detailStore.geocodedLng },17z`,
            'openingHours': formatOpeningHoursToLocalBusinessJSONSchema(detailStore.regularHours.periods)
          })
        ]}
      />
      <section className="map">
        <Map stores={ [detailStore] } />
      </section>
      <StoreHeader>
        <h2>{ detailStore.locationName }</h2>
      </StoreHeader>
      <StoreCover>
        { coverImage() }
      </StoreCover>
      <RowSection>
        <ContactCard
          address={ detailStore.addressLines }
          locality={ detailStore.locality }
          region={ detailStore.geocodedAdministrativeAreaLevel1 }
          phone={ detailStore.primaryPhone }
          latitude={ detailStore.geocodedLat }
          longitude={ detailStore.geocodedLng }
          websiteUrl={ detailStore.websiteUrl }
          isStoreOpen={ isStoreCurrentlyOpen }
        />
        <OpeningHours regularHours={ detailStore.regularHours } />
      </RowSection>
     
      {/* <TextBlock
        title="Section title"
        // eslint-disable-next-line max-len
        text="Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi."
      /> */}
      <ImageSlider images={ sliderImages.length ? sliderImages : [] } />
      <CardsSection>
        <h2>{ formatMessage({ id: 'shopsNearby' }) }</h2>
        <section className="cards">
          { cards }
        </section>
      </CardsSection>
    </Layout>
  )
}

export default Store
