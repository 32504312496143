import React from 'react'
import { Link } from 'gatsby-plugin-react-intl'
import { ICleanedUrl } from '../../../../models'
import StyledDiv from './styles'

interface IProps {
  label: string
  storesCount: number
  cleanedUrl: ICleanedUrl
  region?: string
  isRegion?: boolean
  onClick?: () => void
}

export const Location: React.FC<IProps> = ({ label, storesCount, cleanedUrl, region, isRegion, onClick }) => {
  const handleLocationClick = () => {
    if (onClick && typeof onClick === 'function') onClick()
  }

  return (
    <StyledDiv onClick={ handleLocationClick }>
      <Link
        to={
          isRegion
            ? `/${cleanedUrl.region }/index.html`
            : `/${ cleanedUrl.region }/${ cleanedUrl.locality }/index.html`
        }
      >
        <span>{ label } <span className="count">{ storesCount }</span></span>
      </Link>
    </StyledDiv>
  )
}
